<template>
  <div
    class="block-information-cards"
    :class="{
      'is-medium-wide': isSingle,
      'is-max': !isSingle,
    }"
  >
    <div
      v-if="!isSingle"
      class="block-information-cards__content"
    >
      <div class="block-information-cards__pretitle">
        {{ computedValues.pretitle }}
      </div>
      <div class="block-information-cards__title">
        {{ computedValues.title }}
      </div>
    </div>

    <div v-for="item of computedValues.items" :key="item._uid" class="block-information-cards__item">
      <div class="block-information-cards__item-image">
        <AtomImage :data="item.icon" />
      </div>

      <div class="block-information-cards__item-content">
        <div class="title">
          {{ item.title }}
        </div>
        <div class="text">
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

const globalData = computed(() => {
    const data = getStoryblokSetting('globalcomponents', 'saner_trust');
    if (isArrayNotEmpty(data)) return {};

    return data[0];
});

const isSingle = computed(() => isArrayNotEmpty(props.data?.items)
  && props.data.items.length === 1);

const computedValues = computed(() => {
    const pretitle = props.data?.pretitle || globalData.value.pretitle;
    const title = props.data?.title || globalData.value.title;
    const items = props.data?.items?.length > 0 ? props.data.items : globalData.value.items;

    return {
        pretitle,
        title,
        items,
    };
});

</script>

<style lang="scss" scoped>
.block-information-cards {
    @include default-content-columns;
    @include grid-layout(3);
    @include fluid('row-gap', 10px, 30px);
    @include component-spacing(60px, 120px);

    &.is-medium-wide {
        @include grid-layout(1);
    }

    @include tablet {
        @include grid-layout(2);
    }

    @include mobile {
        @include grid-layout(1);
    }
}

.block-information-cards__item-image {
    @include fluid('width', 60px, 80px);
    @include fluid('height', 55px, 75px)
}

.block-information-cards__item {
    @include fluid('padding', 10px, 43px);
    @include fluid('column-gap', 25px, 46px);
    @include typo-font('regular');
    @include typo-size('nav');

    display: flex;
    align-items: center;
    border-radius: 12px;
    background: $C_GREEN_LIGHT;
    color: $C_GREEN_DARK;
    column-gap: 46px;

    .text {
        @include typo-size('h3');
    }
}

.block-information-cards__content {
    color: $C_GREEN_DARK;
    grid-column-end: span 3;

    @include tablet {
        grid-column-end: span 2;
    }

    @include mobile {
        grid-column-end: span 1;
    }
}

.block-information-cards__title {
    @include typo-size('h2');
    @include typo-font('regular');
}

.block-information-cards__pretitle {
    @include typo-font('light');
    @include fluid('font-size', 15px, 20px);
    @include fluid('margin-bottom', 0px, 6px);
}
</style>
